import React from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import Button from '../components/button'

const OurStoryPage = () => {

    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-57.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            }
        }
    `)

  const HeaderContainer = styled.div ({
    backgroundColor: '#fff',
    padding: '24px',
    boxSizing: 'border-box',
    width: 'calc(100% - 32px)',
    display: 'block',
    margin: 'auto',
    'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    marginTop: "-24px",
    position: 'relative',
    'z-index': '10',
  })


    
  return ( 
    <div>
      <Layout>
        <SEO title="Our Story" />
        <Hero file={data.hero.childImageSharp.fluid} style={{marginTop: '50px'}}/>
        <HeaderContainer>
            <TextBlock
                title="Samantha & Tony"
                subTitle="Wedding Registry"
            >

            </TextBlock>
        </HeaderContainer>
        <Button text="View Our Registry" url="https://www.myregistry.com/wedding-registry/samantha-reinhart-and-tony-mitton-buffalo-ny/2765815" target="_blank" style={{ marginTop: '24px' }}/>
      </Layout>
    </div>  
  )
}

export default OurStoryPage